<div class="flex h-full items-center justify-center">
  <div class="basis-9/12 sm:basis-6/12">
    <mat-card class="mat-elevation-z6 box p-0">
      <div class="box-header flex flex-col items-center justify-center bg-primary-500">
        <mat-icon class="mat-icon-xlg">error</mat-icon>
        <h1 class="error" i18n>404</h1>
      </div>
      <mat-card-content class="box-content flex flex-col items-center justify-end">
        <mat-card
          appearance="outlined"
          class="mat-elevation-z8 box-content-inner flex flex-col items-center justify-center"
        >
          <p class="box-text" i18n>
            It seems that this page does not exist. Contact us for any request that you are having!
          </p>
        </mat-card>
        <div class="box-footer">
          <button mat-raised-button color="primary" class="mat-elevation-z8" (click)="goHome()">
            <mat-icon>home</mat-icon>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
