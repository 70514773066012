import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "./services/authentication/auth.service";
import packageJson from "../../package.json";
import { DataService } from "./services/data.service";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { UserService } from "./services/user.service";
import { filter, firstValueFrom, Subject, takeUntil } from "rxjs";
import { CommonService } from "./services/common.service";
import { OverlaySpinnerComponent } from "./theme/components/overlay-spinner/overlay-spinner.component";
import { CookiesComponent } from "./theme/components/cookies/cookies.component";
import { CommonModule, DOCUMENT } from "@angular/common";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from "@angular/router";
import { SsrService } from "./services/ssr-service";
import { ChatService } from "./services/chat.service";
import { SettingsService } from "./services/settings.service";
import { PaymentService } from "./services/payment.service";
import { PropertyService } from "./services/property.service";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    OverlaySpinnerComponent,
    CookiesComponent,
  ],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  // Whenever a new user is identified we perform a small call to the backend to ensure that the user is successfully saved
  public allowStartup: boolean = false;

  private _unsubscribe$ = new Subject<void>();

  constructor(
    private keycloakService: KeycloakService,
    private settingsService: SettingsService,
    private paymentService: PaymentService,
    private propertyService: PropertyService,
    private chatService: ChatService,
    private dataService: DataService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private commonService: CommonService,
    private ssrService: SsrService,

    @Inject(DOCUMENT) private document: Document
  ) {
    this.dataService.version = packageJson.version;
  }

  public async ngOnInit() {
    // Reset the Meta tags so that by default they are populated
    this.ssrService.resetMetaTags();

    // Subscribe to route stop events to automatically create the canonical links
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // The activated route is app.component
        // We have however a child pages.component children and only then we have the corresponding route
        this.activatedRoute.children[0].children[0].data.subscribe((data) => {
          const canonical = data["canonical"];
          if (canonical) {
            this.ssrService.setCanonicalUrl(canonical);
          } else {
            // In case no canonical is provided we use Homie
            this.ssrService.setCanonicalUrl("");
          }
        });
      });

    // On server we simply start the app without any check
    if (this.ssrService.isServer()) {
      this.allowStartup = true;
      this.authService.isLoggedIn = false;
      return;
    }

    // On Browser do checks
    // Check whether we are logged in at startup
    if (await this.keycloakService.isLoggedIn()) {
      // This needs to be done directly. Otherwise the AuthGuard will execute before the OnInit
      // and it will lead to an invalid race condition
      this.authService.isLoggedIn = true;
      this.keycloakService.enableBearerInterceptor;
      // We first query the user creation endpoint to ensure that the user exists
      await firstValueFrom(this.userService.checkUser())
        .then(() => {
          this.allowStartup = true;
          this.authService.getUserData();
          this.chatService.setUpdateUnreadInterval();
        })
        .catch(() => {
          this.allowStartup = false;
          this.authService.isLoggedIn = false;
          this.commonService.showSnackbar(
            $localize`Something went wrong logging you in. Please contact our support. You will automatically be redirected in 5 seconds.`,
            true
          );
          setTimeout(() => {
            this.authService.logout();
          }, 5000);
        });
    } else {
      this.allowStartup = true;
    }

    // The keycloak library does not correctly remove the hashes. Hence we do it like this for now
    const location = this.ssrService.getLocation();
    if (location) {
      let scrollV, scrollH;
      if ("replaceState" in history)
        history.replaceState(
          "",
          this.document.title,
          location.pathname + location.search
        );
      else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = this.document.body.scrollTop;
        scrollH = this.document.body.scrollLeft;

        location.hash = "";

        // Restore the scroll offset, should be flicker free
        this.document.body.scrollTop = scrollV;
        this.document.body.scrollLeft = scrollH;
      }
    }

    this.authService.logout$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(() => {
        this.chatService.resetService();
        this.userService.resetService();
        this.paymentService.resetService();
        this.settingsService.resetService();
        this.propertyService.resetService();
      });

    this.keycloakService.keycloakEvents$.subscribe((event) => {
      if (event.type == KeycloakEventType.OnTokenExpired) {
        console.log("Keycloak token expired. Updating");
        this.keycloakService.updateToken(20);
      }
      if (
        [
          KeycloakEventType.OnAuthRefreshError,
          KeycloakEventType.OnAuthError,
          KeycloakEventType.OnAuthLogout,
        ].includes(event.type)
      ) {
        this.authService.logout();
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
  }
}
