<!-- This generates the spinner that is shown while the app is loading -->
<div
  class="spinner-wrapper bg-white/50 flex flex-col items-center justify-center absolute w-screen h-screen z-50"
  id="spinner"
>
  <div class="max-w-screen w-[500px]">
    <img
      [attr.src]="dataService.assetsUrl + '/logos/homie_animated.svg'"
      alt="Homie Loading Spinner"
      class="max-w-screen w-[500px]"
    />
    <!-- Progress bar -->
    <mat-progress-bar
      class="max-w-screen mt-2 w-[500px] px-5"
      [mode]="spinnerService.mode"
      [value]="spinnerService.percentage"
    ></mat-progress-bar>

    <div id="spinner-progress-text" class="spinner-progress-text"></div>
  </div>
</div>
