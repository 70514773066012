<footer class="bg-gray-800">
  <div class="p-4 py-6 mx-auto max-w-screen-xl md:p-8 lg:-10">
    <div class="grid grid-cols-2 gap-8 lg:grid-cols-6">
      <div class="col-span-2">
        <a href="/" routerLink="/" class="flex items-center">
          <img
            [attr.src]="
              dataService.assetsUrl + '/logos/homie_primary_whitedoor.svg'
            "
            class="mr-3 h-8"
            alt="Homie Logo"
          />
        </a>
        <p i18n class="my-4 font-light text-gray-400">
          Homie is a free real estate portal for Luxembourg. We believe that
          Luxembourg is ready for a change.
        </p>
        <ul class="flex mt-5 space-x-6">
          <li>
            <!-- Facebook logo-->
            <a
              href="https://www.facebook.com/homie.lu"
              target="_blank"
              class="hover:text-white text-gray-400"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </li>
          <!-- Linkedin logo -->
          <li>
            <a
              href="https://linkedin.com/company/homielu"
              target="_blank"
              class="hover:text-white text-gray-400"
            >
              <svg
                class="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z"
                  clip-rule="evenodd"
                />
                <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
              </svg>
            </a>
          </li>
        </ul>
      </div>
      <div class="lg:mx-auto">
        <h2 i18n class="mb-6 text-sm font-semibold uppercase text-white">
          Properties
        </h2>
        <ul class="text-gray-400">
          <li class="mb-4">
            <a
              i18n
              href="/properties/sale"
              routerLink="/properties/sale"
              class="hover:underline"
              >Sale</a
            >
          </li>
          <li class="mb-4">
            <a
              i18n
              href="/properties/rent"
              routerLink="/properties/rent"
              class="hover:underline"
              >Rental</a
            >
          </li>
          <li class="mb-4">
            <a
              i18n
              href="/analysis"
              routerLink="/analysis"
              class="hover:underline"
              >Our Data</a
            >
          </li>
        </ul>
      </div>
      <div class="lg:mx-auto">
        <h2 class="mb-6 text-sm font-semibold uppercase text-white" i18n>
          Help center
        </h2>
        <ul class="text-gray-400">
          <li class="mb-4">
            <a
              i18n
              href="/consulting"
              routerLink="/consulting"
              class="hover:underline"
              >Consulting Services</a
            >
          </li>
          <li class="mb-4">
            <div class="flex flex-col">
              <span class="text-white">Questionnaire</span>
              <span
                ><a
                  href="/surveys/buyer"
                  routerLink="/surveys/buyer"
                  class="hover:underline inline"
                  i18n
                  >Buyer</a
                >/<a
                  href="/surveys/seller"
                  routerLink="/surveys/seller"
                  class="hover:underline inline"
                  i18n
                  >Seller</a
                >/<a
                  href="/surveys/landlord"
                  routerLink="/surveys/landlord"
                  class="hover:underline inline"
                  i18n
                  >Landlord</a
                >/<a
                  href="/surveys/tenant"
                  routerLink="/surveys/tenant"
                  class="hover:underline inline"
                  i18n
                  >Tenant</a
                ></span
              >
            </div>
          </li>

          <li class="mb-4">
            <div class="flex flex-col">
              <span class="text-white">Step by Step</span
              ><span>
                <a
                  href="/guides/buyer/steps"
                  routerLink="/guides/buyer/steps"
                  class="hover:underline inline"
                  i18n
                  >Buyer</a
                >/
                <a
                  href="/guides/seller/steps"
                  routerLink="/guides/seller/steps"
                  class="hover:underline inline"
                  i18n
                  >Seller</a
                >/
                <a
                  href="/guides/landlord/steps"
                  routerLink="/guides/landlord/steps"
                  class="hover:underline inline"
                  i18n
                  >Landlord</a
                >/
                <a
                  href="/guides/tenant/steps"
                  routerLink="/guides/tenant/steps"
                  class="hover:underline inline"
                  i18n
                  >Tenant</a
                ></span
              >
            </div>
          </li>
        </ul>
      </div>
      <div class="lg:mx-auto">
        <h2 class="mb-6 text-sm font-semibold uppercase text-white" i18n>
          Legal
        </h2>
        <ul class="text-gray-400">
          <li class="mb-4">
            <a
              href="/privacy-policy"
              routerLink="/privacy-policy"
              class="hover:underline"
              i18n
              >Privacy Policy</a
            >
          </li>
          <li class="mb-4">
            <a
              href="/terms-conditions"
              routerLink="/terms-conditions"
              class="hover:underline"
              i18n
              >Terms and Conditions</a
            >
          </li>
        </ul>
      </div>
      <div class="lg:mx-auto">
        <h2 class="mb-6 text-sm font-semibold uppercase text-white" i18n>
          Contact
        </h2>
        <ul class="text-gray-400">
          <li class="mb-4">
            <a
              href="/contact"
              routerLink="/contact"
              class="hover:underline"
              i18n
              >Contact Us</a
            >
          </li>
          <li class="mb-4">
            <a
              i18n
              href="https://www.facebook.com/homie.lu"
              target="_blank"
              class="hover:underline"
              >Facebook</a
            >
          </li>
          <li class="mb-4">
            <a
              i18n
              href="https://linkedin.com/company/homielu"
              target="_blank"
              class="hover:underline"
              >Linkedin</a
            >
          </li>
        </ul>
      </div>
    </div>
    <hr class="my-6 border-gray-700 lg:my-8" />
    <span i18n class="block text-sm text-center text-gray-400"
      >© 2024 <a href="/" routerLink="/" class="hover:underline">Homie</a>. All
      Rights Reserved.</span
    >
  </div>
</footer>
