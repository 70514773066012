import { Component, ViewChild, Inject, OnInit } from "@angular/core";
import {
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from "@angular/router";
import { DataService } from "../services/data.service";
import { SpinnerService } from "../services/spinner.service";
import { CommonModule, DOCUMENT } from "@angular/common";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { VerticalMenuComponent } from "../theme/components/menu/vertical-menu/vertical-menu.component";
import { SocialIconsComponent } from "../theme/components/social-icons/social-icons.component";
import { ToolbarComponent } from "../theme/components/toolbar/toolbar.component";
import { FooterComponent } from "../theme/components/footer/footer.component";
import { SsrService } from "../services/ssr-service";

@Component({
  selector: "app-pages",
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    VerticalMenuComponent,
    SocialIconsComponent,
    ToolbarComponent,
    RouterOutlet,
    FooterComponent,
  ],
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
})
export class PagesComponent implements OnInit {
  @ViewChild("sidenav") sidenav: any;

  constructor(
    private router: Router,
    private spinnerService: SpinnerService,
    public dataService: DataService,
    private ssrService: SsrService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  async ngOnInit() {
    // Hide the spinner after the pages have been loaded
    this.spinnerService.disableSpinner();
    if (this.ssrService.isBrowser()) {
      this.initialise();
    }
  }

  private initialise() {
    // Close the sidenav in case the page has changed (mainly in case we pressed on an item in the vertical menu)
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();

        // We also need to scroll back to the top (this is for example the case if a button on the bottom of a page redirects the user to a new page)
        const sidenavContent: any = this.document.querySelector(
          ".mat-sidenav-content"
        );
        sidenavContent.scroll({
          top: 0,
          left: 0,
        });
      }
    });
  }

  public showFooter() {
    const doNotShowFooter = ["/properties/rent", "/properties/sale"];
    return !doNotShowFooter.includes(this.router.url);
  }
}
