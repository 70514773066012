<header class="antialiased border-b-2 border-b-accent-100">
  <nav
    aria-label="Top toolbar containing language change buttons"
    class="bg-primary-500 border-primary-200 px-4 lg:px-6"
  >
    <div class="flex justify-between items-center max-w-4xl mx-auto">
      <div class="flex justify-start items-center">
        <a routerLink="/" href="/" class="mr-4">
          <img
            [attr.src]="dataService.assetsUrl + '/logos/homie_accent_whitedoor.svg'"
            class="mr-3 h-6"
            alt="Homie Logo"
          />
        </a>
      </div>
      <app-lang></app-lang>
    </div>
  </nav>
  <nav
    aria-label="Bottom toolbar containg navigation menu"
    class="bg-white py-1 flex justify-between mx-auto items-center w-full max-w-4xl"
  >
    <span class="sm:hidden">
      <!-- This enables the sidebar in the mobile view-->
      <button class="mx-4 text-gray-800 hover:bg-gray-200 p-3 rounded-lg" (click)="sidenavToggle()">
        <svg
          class="w-6 h-6"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-width="2"
            d="M5 7h14M5 12h14M5 17h14"
          />
        </svg>
      </button>
    </span>
    <div class="hidden sm:inline-block">
      <app-horizontal-menu></app-horizontal-menu>
    </div>
    <div class="flex items-center">
      @if (authService.isLoggedIn) {
        <a
          routerLink="/favorites"
          href="/favorites"
          class="text-gray-800 rounded-lg p-3 hover:bg-gray-200 mx-4"
        >
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12.01 6.001C6.5 1 1 8 5.782 13.001L12.011 20l6.23-7C23 8 17.5 1 12.01 6.002Z"
            />
          </svg>
        </a>

        <span class="mx-2 w-px h-4 bg-accent-500"></span>
      }
      <app-user-menu></app-user-menu>
    </div>
  </nav>
</header>
