@for (menu of menuItems; track menu) {
  <div class="menu-item">
    <a
      mat-button
      class="flex items-center justify-start"
      [routerLink]="[menu.routerLink]"
      [id]="'menu-item-' + menu.title"
      >
      <span class="menu-title">{{ menu.title }}</span>
    </a>
  </div>
}
