import { HttpInterceptorFn } from "@angular/common/http";
import { DataService } from "../services/data.service";
import { inject } from "@angular/core";

export const noCacheInterceptor: HttpInterceptorFn = (req, next) => {
  // const dataService = inject(DataService);
  // if (req.url.startsWith(dataService.backendUrl)) {
  //   const noCacheReq = req.clone({
  //     setParams: {
  //       salt: "" + new Date().valueOf(),
  //     },
  //   });
  //   return next(noCacheReq);
  // }

  // Else we return the same url
  return next(req);
};
