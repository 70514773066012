export class Menu {
  constructor(
    public title: string,
    public routerLink: string,
    public additionalClass: string | null
  ) {}
}

// The menu is used in order to define new links in the NAVBAR
// Horizontal menu for large devices
export const MenuItems = [
  new Menu($localize`Home`, "/", null),
  new Menu($localize`For Sale`, "/properties/sale", null),
  new Menu($localize`For Rent`, "/properties/rent", null),
  new Menu($localize`Data`, "/analysis", null),
  new Menu($localize`Consulting`, "/consulting", null),
];
