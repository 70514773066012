import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AuthService } from "../../../services/authentication/auth.service";
import { ChatService } from "../../../services/chat.service";
import { PaymentService } from "../../../services/payment.service";
import { PropertyService } from "../../../services/property.service";
import { UserService } from "../../../services/user.service";
import { FlowbiteService } from "../../../services/flowbite.service";

// This is used to show the user menu ones he presses the account button in the header
@Component({
  selector: "app-user-menu",
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
})
export class UserMenuComponent implements OnInit, AfterViewInit {
  constructor(
    public paymentService: PaymentService,
    public authService: AuthService,
    public userService: UserService,
    public chatService: ChatService,
    private flowbiteService: FlowbiteService,
    public propertyService: PropertyService
  ) {}


  ngAfterViewInit() {
    this.flowbiteService.loadFlowbite(flowbite => flowbite.initDropdowns())
  }

  ngOnInit() {
    // Find out whether the user has properties
    if (this.authService.isLoggedIn) {
      this.propertyService.getMyProperties();
    }
  }

  public closeDropdown() {
    const $targetEl = document.getElementById("userMenuDropdown");
    // set the element that trigger the dropdown menu on click
    const $triggerEl = document.getElementById("userMenuDropdownButton");
    this.flowbiteService.loadFlowbite(flowbite => {
      const dropdown = new flowbite.Dropdown($targetEl, $triggerEl, {}, {});
      dropdown.hide();
    })
  }
}
