<div class="flex h-full flex-col items-stretch justify-start">
  <!-- Header which is shown on top in green and pages -->
  <app-toolbar (onMenuIconClick)="sidenav.toggle()"></app-toolbar>
  <mat-sidenav-container class="flex-grow">
    <!-- This is the main sidebar that is used for small devices for example -->
    <mat-sidenav
      [opened]="false"
      mode="over"
      #sidenav
      class="sidenav mat-elevation-z6"
    >
      <div class="flex h-full flex-col items-stretch justify-start">
        <button
          mat-icon-button
          color="warn"
          class="close"
          (click)="sidenav.close()"
        >
          <mat-icon color="warn">close</mat-icon>
        </button>
        <div class="divider"></div>
        <div class="grow">
          <app-vertical-menu></app-vertical-menu>
        </div>
        <div class="divider"></div>
        <app-social-icons
          [iconSize]="'xlg'"
          [iconColor]="'primary'"
          class="flex items-center justify-center"
        >
        </app-social-icons>
      </div>
    </mat-sidenav>
    <!-- This is the main page -->
    <mat-sidenav-content
      class="flex h-full flex-col items-stretch justify-start"
    >
      <!-- The main router outlet which depends on the current user interactions -->
      <main class="grow">
        <router-outlet></router-outlet>
      </main>
      <!-- And finally the footer which shows the contact information etc -->
      @if (showFooter()) {
        <app-footer></app-footer>
      }
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
