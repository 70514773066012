@if (!isCookiesHidden()) {
  <!-- The overlay so that the user is not able to click on anything else -->
  <div id="cookie-overlay" class="cookie-overlay"></div>
  <!-- The popup that show the information to accept-->
  <div id="cookie-popup" class="cookie-popup">
    <div class="flex flex-wrap items-center justify-center mx-auto max-w-2xl">
      <div class="ml-2 flex basis-full flex-col items-center justify-start sm:basis-9/12">
        <h3 class="font-semibold text-primary-500" i18n>We value your privacy</h3>
        <p i18n>
          We are not using any cookies to track or monitor your browsing behaviour. You only need to
          accept our
          <a class="underline" href="./terms-conditions" target="_blank">Terms and Conditions</a>
          and our <a class="underline" href="./privacy-policy" target="_blank">Privacy Policy</a>.
        </p>
      </div>
      <button
        i18n
        type="button"
        (click)="acceptCookies()"
        class="sm:mx-auto sm:mt-2 justify-center text-white inline-flex bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
        Accept
      </button>
    </div>
  </div>
}
