import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { AuthService } from "./auth.service";
import { SsrService } from "../ssr-service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly authService: AuthService,
    private ssrService: SsrService
  ) {
    // On server side the keycloak is not initialised.
    // Hence this gives an error. For now it seems that this error does not affect the app
    // ToDo fix this
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // On server we simply allow access
    if (!this.ssrService.isBrowser()) {
      return true;
    }

    // On browser we check whther the user is logged in
    const loggedIn = this.authService.isLoggedIn || this.keycloak.isLoggedIn();
    if (!loggedIn) {
      const location = this.ssrService.getLocation();
      if (location) {
        this.keycloak.login({
          redirectUri: location.origin + state.url,
        });
      }
      return false;
    } else {
      return true;
    }
  }
}
