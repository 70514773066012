import { APP_BASE_HREF, CommonModule } from "@angular/common";
import { Component, Inject, InjectionToken, OnInit, Optional } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { DataService } from "../../../services/data.service";
import { SsrService } from "../../../services/ssr-service";
import { TranslationService } from "../../../services/translation.service";
@Component({
  selector: "app-lang",
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatButtonModule, MatIconModule],
  templateUrl: "./lang.component.html",
  styleUrls: ["./lang.component.scss"],
})
export class LangComponent implements OnInit {
  public languages: string[] = ["en", "fr", "lb"];

  constructor(
    public dataService: DataService,
    public translationService: TranslationService,
    private ssrService: SsrService,
    @Optional() @Inject(APP_BASE_HREF) private baseHref: InjectionToken<string>
  ) {}

  ngOnInit() {
    const location = this.ssrService.getLocation();
    // In case of browser side
    if (location) {
      const pathname = location.pathname;
      if (pathname.startsWith("/en/")) {
        this.translationService.language = "en";
      } else if (pathname.startsWith("/fr/")) {
        this.translationService.language = "fr";
      } else if (pathname.startsWith("/lb/")) {
        this.translationService.language = "lb";
      } else {
        // In case we have a prefered language, we move to that language
        const preferedLanguage = this.ssrService.getLocalStorage("language");
        if (preferedLanguage && preferedLanguage !== "lb") {
          console.log("Prefered language is not luxembourgish. Switching");
          this.changeLang(preferedLanguage);
        } else {
          this.translationService.language = "lb";
        }
      }
      // In case of server side
    } else if (this.baseHref) {
      // Slice is needed to convert from /lb to lb
      this.translationService.language = this.baseHref.toString().slice(1, -1) as
        | "en"
        | "fr"
        | "lb";
    } else {
      console.log(
        "No basehref, please find out why it is not send in the node express server (on localhost this is normal)"
      );
    }
  }

  public changeLang(lang: string) {
    this.ssrService.setLocalStorage("language", lang);
    const location = this.ssrService.getLocation();
    if (location) {
      const newPath = location.pathname.replace(
        `/${this.translationService.language}/`,
        `/${lang}/`
      );
      this.ssrService.getWindow()?.open(newPath, "_self");
    }
  }

  public getLangName(lang: string): string {
    if (lang == "en") {
      return "English";
    } else if (lang == "fr") {
      return "Francais";
    } else if (lang == "lb") {
      return "Lëtzebuergesch";
    } else {
      return "";
    }
  }
}
