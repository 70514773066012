import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MenuItems, Menu } from "../menu";

@Component({
  selector: "app-vertical-menu",
  standalone: true,
  imports: [CommonModule, RouterModule, MatButtonModule],
  templateUrl: "./vertical-menu.component.html",
  styleUrls: ["./vertical-menu.component.scss"],
})
export class VerticalMenuComponent {
  public menuItems: Array<Menu> = MenuItems;
}
