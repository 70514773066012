<button
  mat-button
  [matMenuTriggerFor]="langMenu"
  #langMenuTrigger="matMenuTrigger"
>
  <img
    [src]="
      dataService.assetsUrl +
      '/images/flags/' +
      translationService.language +
      '.svg'
    "
    width="18"
    [alt]="getLangName(translationService.language)"
    class="inline-block"
  />
  <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
</button>
<mat-menu #langMenu="matMenu" [overlapTrigger]="false">
  <span (mouseleave)="langMenuTrigger.closeMenu()">
    @for (lang of languages; track lang) {
      <button mat-menu-item (click)="changeLang(lang)">
        <img
          class="inline-block"
          [src]="dataService.assetsUrl + '/images/flags/' + lang + '.svg'"
          width="18"
          [alt]="lang"
        />
        <span class="ml-1">{{ getLangName(lang) }}</span>
      </button>
    }
  </span>
</mat-menu>
