import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(catchError((x) => handleAuthError(x)));
};

function handleAuthError(err: HttpErrorResponse): Observable<any> {
  //handle your auth error or rethrow
  if (err.status === 401) {
    console.log("401 error detected. What should we do in auth interceptor now? - error is ");
    console.log(err);
    // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
    // return of(err.message); // or EMPTY may be appropriate here
  }
  return throwError(() => err);
}
